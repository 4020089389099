import Input from "../Input";
import { Frame } from "../../assets";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import PlacesAutocomplete from "react-places-autocomplete";
import { fiberPlans } from "../../utils/mocks/pricingMocks";
import { checkEmail, checkPhoneNumber } from "../../utils/utilities";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {
  RegisterNewUser,
  checkOut,
  useGetCities,
  useGetStates,
  videoUpload,
} from "../../utils/actions";
import { Link } from "react-router-dom";

const SignupForm = ({ data, setSchedule, setInfo }) => {
  const formData = new FormData();
  const { filteredState: states, isFetchingState } = useGetStates();
  const [address, setAddress] = useState("");
  const [formatted_address, setFormatted_address] = useState("");
  const [state, setState] = useState("Lagos");
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [doc, setDoc] = useState({ file: "", show: "" });

  const [price, setPrice] = useState(data ? data.price : "");

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    nin: "",
    country: "ng",
    state: state,
    city: "",
    address: "",
    file: "",
    plan: data ? data.plan : "",
    about_us: "",
    lat: "",
    lng: "",
    amount: data ? data.amount : "",
    allowEmail: true,
  };

  const handleChangeAddress = (address) => setAddress({ address });
  const handlePhoneNumberChanges = (e) => checkPhoneNumber(e.target.value);

  const {
    cityIsLoading,
    data: cities,
    refetch: fetchCities,
  } = useGetCities(state);

  // const handleOptionChange = (value) => {
  //   if (name === "plan") {

  //   } else if (e.target.name === "state") {

  //   }
  // };

  useEffect(() => {
    fetchCities();
  }, [state, fetchCities]);

  const handleFileChange = (e) => {
    // Reset the file's state
    setDoc({ file: "", show: "" });
    const file = e;

    const videoTypes = [
      "video/mp4",
      "video/mpeg",
      "video/webm",
      "video/ogg",
      "video/quicktime",
      "video/mov",
    ];

    if (!file) {
      return;
    }

    if (!videoTypes.includes(file.type)) {
      toast.error("Please select a valid video file.");
      return;
    }

    if (file.size / 1000000 > 100) {
      toast.error("File is larger than 100MB");
      return;
    }

    const videoURL = URL.createObjectURL(file);

    setDoc({
      file: file,
      show: videoURL,
    });
  };

  const handleAddressSelect = async (address) => {
    try {
      const result = await geocodeByAddress(address);
      const latLng = await getLatLng(result[0]);

      setAddress({ address, direction: latLng });
      setFormatted_address(result[0].formatted_address);
    } catch (error) {}
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  async function onSubmit(payload) {
    setIsSubmitting(true);
    if (
      payload.amount &&
      payload?.lat > 0 &&
      payload?.lng > 0 &&
      handlePhoneNumberChanges &&
      isChecked
    ) {
      try {
        await RegisterNewUser(payload).then((res) => {
          if (res.status) {
            setIsSubmitting(false);
            checkOut(
              { ...res.data, amount: data.amount },
              setSchedule,
              setInfo
            );
            setInfo((prev) => {
              return {
                ...prev,
                formatted_address: formatted_address,
              };
            });

            formData.append("file", doc.file);
            formData.append("phoneNumber", data.phoneNumber);
            formData.append("email", data.email);

            videoUpload(formData);
          }
        });
      } catch (err) {
        setIsSubmitting(false);
        toast.error(
          `${
            err.response.data
              ? err.response.data.message
              : "Something went wrong"
          }`
        );
      }
    }
  }

  return (
    <div>
      <div className="w-full h-[45vh] bg-form-banner bg-cover bg-no-repeat bg-bottom"></div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (
            checkEmail(values.email) &&
            checkPhoneNumber(values.phoneNumber)
          ) {
            onSubmit({
              ...values,
              // ...options,
              address: address.address,
              ...address?.direction,
              file: doc.file,
            });
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="my-4 p-10 bg-primary-transparent shadow-md rounded-lg relative z-30">
              <div className="flex flex-col items-center justify-center p-4">
                <h3 className="text-black font-bold">Sign up for Free Fiber</h3>
                <h4 className="text-gray-600 text-xl font-medium p-1">
                  {!!price ? `Starting at ${price}` : ""}
                </h4>
              </div>

              <div className="max-w-[640px] w-full m-auto flex flex-col items-center justify-center py-6">
                <div className="w-full overflow-x-hidden text-gray-700 text-xl font-semibold flex flex-col gap-10">
                  <div className="mt-10 px-4">
                    <label>First Name</label>
                    <br />
                    <Field
                      type="text"
                      name="firstName"
                      required={true}
                      className="place-holder"
                      placeholder=""
                    />
                  </div>

                  <div className="px-2">
                    <label>Last Name</label>
                    <br />
                    <Field
                      type="text"
                      name="lastName"
                      required={true}
                      className="place-holder"
                      placeholder=""
                    />
                  </div>

                  <div className="px-2">
                    <label>Email Address</label>
                    <br />
                    <Field
                      type="email"
                      name="email"
                      required={true}
                      onBlur={(e) => checkEmail(e.target.value)}
                      className="place-holder"
                      placeholder=""
                    />
                  </div>

                  <div className="px-2">
                    <label>Phone Number</label>
                    <br />
                    <Field
                      type="tel"
                      name="phoneNumber"
                      required={true}
                      className="place-holder"
                      placeholder=""
                      onBlur={(e) => checkPhoneNumber(e.target.value)}
                    />
                  </div>

                  <div className="px-2">
                    <label>Alternate Phone Number</label>
                    <br />
                    <Field
                      type="tel"
                      name="alternatePhoneNumber"
                      onBlur={(e) => checkPhoneNumber(e.target.value)}
                      className="place-holder"
                      placeholder=""
                    />
                  </div>

                  <div className="px-2">
                    <div className="flex flex-row gap-1">
                      <label>NIN</label>
                      <p className="p-1">
                        <img src={Frame} alt="frame"></img>
                      </p>
                      <br />
                    </div>
                    <Field
                      type="text"
                      name="nin"
                      required={true}
                      className="place-holder"
                      placeholder=""
                    />
                  </div>

                  <div className="px-2">
                    <label>State</label>
                    <br />
                    <Field
                      as="select"
                      name="state"
                      id="state"
                      value={state}
                      onChange={(e) => {
                        const value = e.target.value;
                        setState(value);
                        setFieldValue("state", value);
                      }}
                      required={true}
                      className="place-holder_select "
                    >
                      <option value="">Select State</option>

                      {states && !isFetchingState ? (
                        states?.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))
                      ) : (
                        <option disabled>Loading...</option>
                      )}
                    </Field>
                  </div>

                  <div className="px-2">
                    <label>Select City</label>
                    <Field
                      as="select"
                      className="place-holder_select"
                      name="city"
                      id="city"
                      // onChange={handleOptionChange}
                      required={true}
                    >
                      <option value="">Select an option city</option>
                      {!cityIsLoading ? (
                        cities?.data.map((city, index) => (
                          <option key={index} value={city.city}>
                            {city.city}
                          </option>
                        ))
                      ) : (
                        <option disabled>Loading...</option>
                      )}
                    </Field>
                  </div>

                  <div className="px-2">
                    <PlacesAutocomplete
                      name="address"
                      value={address ? address.address : ""}
                      onChange={handleChangeAddress}
                      onSelect={handleAddressSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <label>Physical Address</label>
                          <Field
                            as={Input}
                            {...getInputProps({
                              label: "",
                              placeholder: "Search Places ...",
                              name: "address",
                              autoComplete: "off",
                            })}
                            required
                          />
                          <div className="autocomplete-dropdown-container w-full flex flex-col gap-2">
                            {loading && <div>Loading Addresses...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              //
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                  <div className="flex flex-col gap-2 w-[95%] m-auto">
                    <label>Upload Video</label>

                    <p className="text-faded-black font-normal text-base">
                      Please share a walkthrough video of your compound,
                      starting from the entrance of your property, to the
                      location where the fiber internet will be connected.
                      Please note to capture obstacles to a smooth thoroughfare
                      so we understand the complexities. Uploaded file is
                      limited to a 100MB
                    </p>

                    <div className="p-4 flex flex-col space-y-4 items-center mt-0 bg-teal-500/10 border border-primary-light rounded-md border-dashed focus:ring focus:border-blue-300 ">
                      {doc.show && (
                        <video
                          alt="Preview"
                          controls
                          className="w-full max-h-48 object-cover"
                        >
                          <source src={doc.show} />
                        </video>
                      )}
                      <div className="relative cursor-pointer">
                        <span className="relative bg-primary-light text-white rounded-md py-2 px-4 mb-2">
                          Browse files
                        </span>
                        <input
                          type="file"
                          name="file"
                          accept="video/*"
                          required={true}
                          placeholder=""
                          className="opacity-0 absolute z-10 left-0"
                          onChange={(e) => handleFileChange(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="px-2">
                    <label>Choose Fiber Plan</label>
                    <br />
                    <Field
                      as="select"
                      className="place-holder_select"
                      required
                      name="plan"
                      onChange={(e) => {
                        const value = e.target.value;
                        setPrice(
                          fiberPlans.find((plan) => plan.plan === value)?.price
                        );
                        setFieldValue("plan", value || "");
                      }}
                    >
                      {fiberPlans.map((item, index) => (
                        <option key={index} value={item.plan}>
                          {item.speed} {item.price}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className="px-2 pb-1">
                    <label>How did you hear about us?</label>
                    <br />
                    <Field
                      as="select"
                      name="about_us"
                      id=""
                      className="place-holder_select"
                      placeholder="feedback"
                      // onChange={handleOptionChange}
                    >
                      <option value="">Select</option>
                      <option value="bigBrotherNaija">Big Brother Naija</option>
                      <option value="googleAds">Google ads</option>
                      <option value="facebook">Facebook</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="flyer">Flyer</option>
                      <option value="billboard">Billboard</option>
                      <option value="mobileAdvert">Mobile Advert</option>
                      <option value="friend">Friend</option>
                      <option value="instagram">Instagram</option>
                      <option value="television">Television</option>
                      <option value="radio">Radio</option>
                      <option value="other">Other</option>
                    </Field>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center m-4">
                <Field
                  type="checkbox"
                  id="myCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-4 w-4 mr-1 text-teal-500"
                  required
                />
                <label htmlFor="myCheckbox" className="text-[16px] font-medium">
                  By signing up, you agree to our
                  <Link to="/userAgreement" className="text-teal-500">
                    {" "}
                    Terms and Conditions
                  </Link>
                </label>
              </div>

              <div className="flex flex-col items-center justify-center mt-auto mb-4 md:mb-7">
                <button
                  type="submit"
                  className={`rounded-md mt-10 text-2xl  flex px-32 py-2 justify-center items-center text-white ${
                    isSubmitting ? "bg-gray-400" : "bg-primary-light"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Proceed"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupForm;
