import React, { useState } from "react";
import { FcMenu } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import { Logo } from "../../assets";

function Navbar() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <nav className="m-0 flex items-center justify-between px-[8%] py-4 bg-gray-50 shadow-md sticky top-0 left-0 w-screen  z-50" style={{zIndex: '9999999'}}>
      <Link to="/" className="h-14 lg:h-20 overflow-hidden flex items-start justify-start">
        <img
          src={Logo}
          alt="Logo"
          className=" h-full w-2/4 object-contain mix-blend-multiply"
        ></img>
      </Link>

      <ul className=" hidden lg:flex items-center list-none gap-8 capitalize text-base font-bold">
        <li className="transition-all hover:text-primary  hover:cursor-pointer">
          <NavLink to="/about" className="w-full block p-2 ">
            <span className="">About Free Fiber</span>
          </NavLink>
        </li>
        <li className="transition-all hover:text-primary  hover:cursor-pointer">
          <a href="/#plans" className="w-full block  p-2 ">
            <span className="">Plans and Pricing</span>
          </a>
        </li>
        <li className="transition-all hover:text-primary  hover:cursor-pointer">
          <NavLink to="/FAQs" className="w-full block  p-2 ">
            <span className="">FAQ's</span>
          </NavLink>
        </li>
        <li className="transition-all hover:text-primary  hover:cursor-pointer">
          <NavLink to="/coverage" className="w-full block  p-2 ">
            <span className="">Check Availability</span>
          </NavLink>
        </li>
      </ul>

      <div className="lg:hidden relative">
        <button className="" onClick={() => setIsMenuOpened(!isMenuOpened)}>
          {isMenuOpened ? (
            <IoMdClose className="w-6 aspect-square text-4xl" />
          ) : (
            <FcMenu className="w-6 aspect-square text-4xl" />
          )}
        </button>
        {isMenuOpened && (
          <ul className="flex flex-col list-none capitalize text-sm font-bold absolute top:4/5 -right-6 bg-white py-4 w-[100vw] z-40">
            <li className="transition-all hover:text-primary hover:cursor-pointer border-b-[0.6px] border-gray-200 py-3 ">
              <NavLink to="/about" className="w-full block  p-2 px-12 ">
                <span className="">About Free Fiber</span>
              </NavLink>
            </li>
            <li className="transition-all hover:text-primary hover:cursor-pointer border-b-[0.6px] border-gray-200 py-3 ">
              <NavLink to="/#plans" className="w-full block  p-2 px-12 ">
                <span className="">Plans and Pricing</span>
              </NavLink>
            </li>
            <li className="transition-all hover:text-primary hover:cursor-pointer border-b-[0.6px] border-gray-200 py-3 ">
              <NavLink to="/FAQs" className="w-full block  p-2 px-12 ">
                <span className="">FAQ's</span>
              </NavLink>
            </li>
            <li className="transition-all hover:text-primary hover:cursor-pointer border-b-[0.6px] border-gray-200 py-3 ">
              <NavLink
                to="/coverage"
                className="w-full block  p-2 px-12 "
              >
                <span className="">Check Availability</span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
